<template>
  <div class="productList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>分销列表</el-breadcrumb-item>
    </el-breadcrumb>

		<div class="search_box">
			<span>商品名称：</span>
			<el-input
        style="width: 200px;"
				v-model="formData.prodName"
				placeholder="请输入商品名称"
			/>
			<span>下单人：</span>
			<el-input
        style="width: 200px;"
				v-model="formData.trueName"
				placeholder="请输入姓名"
			/>
			<span>下单人手机：</span>
			<el-input
        style="width: 200px;"
				v-model="formData.phone"
				placeholder="请输入手机号"
			/>
      <el-button type="primary" class="btn" @click="getTableList()">查询</el-button>
		      <el-button type="primary" class="btn" @click="createForm()">生成报表</el-button>
		</div>
    
    <el-table
			border
			ref="multipleTable"
			:data="tableData"
			tooltip-effect="dark"
			style="width: 100%">

			<el-table-column prop="coding" label="订单号">
			</el-table-column>
			<el-table-column prop="prodName" label="商品名称">
			</el-table-column>
			<el-table-column prop="count" label="购买数量"></el-table-column>
			<el-table-column prop="totalPrice" label="总价格"></el-table-column>
			<el-table-column prop="couponPrice" label="优惠价格"></el-table-column>
			<el-table-column prop="beginTime" label="分销时间"></el-table-column>
			<el-table-column prop="trueName" label="下单人姓名">
      </el-table-column>
			<el-table-column prop="phone" label="手机号"></el-table-column>
      <el-table-column prop="accountWay" label="佣金结算方式"></el-table-column>
			<el-table-column prop="userProfitLevel" label="分销等级"></el-table-column>
			<el-table-column prop="firstProfitRatio" label="一级分销比例"></el-table-column>
			<el-table-column prop="firstProfit" label="一级分销金额"></el-table-column>
			<el-table-column prop="secondProfitRatio" label="二级分销比例"></el-table-column>
			<el-table-column prop="secondProfit" label="二级分销金额"></el-table-column>
    </el-table>
		<el-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="formData.currentPage"
			:page-sizes="[5, 10, 15, 20]"
			:page-size="formData.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="formData.total"
		>
		</el-pagination>
    
  </div>
</template>

<script>
import { shopMoneyRecord, shopownerApply,createForm } from '../../api/wanxiaoShop.js'
import { sendFile } from "../../api/sendFile";
export default {
	name: "shopRetailRecord",
  data() {
    return {
      options: [],
			formData: {
        prodName: '',
				total: 0,
        trueName:'',
        phone:'',
				pageSize: 10,
				currentPage: 1,
			},
      editForm: {
        status: '',
        remark: '',
      },
			tableData: [],
			multipleSelection: [],
      dialogVisible: false,
      dialogVisible2: false,
    };
  },
  created() {
  },
  mounted() {
		this.getTableList(); // 获取表格数据
  },
  methods: {
    //下载报表
    async createForm(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
     let date = +new Date();
     const { data } = await createForm(this.formData);
     console.log(data)
     let url = window.URL.createObjectURL(data)//转换文件流为URL
     let link = document.createElement("a");
     link.style.display = "none";
     link.href = url;
     link.setAttribute("download", `万旅网小程序分销报表_${date}.xls`);
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
      loading.close()
    },
    async getTableList() {
      const { data } = await shopMoneyRecord(this.formData);
			this.tableData = data.list;
      // this.formData.userName = data.list.map(item=>{
      //   return item.trueName
      // })
      // this.formData.phone = data.list.map(item=>{
      //   return item.phone
      // })
			this.formData.total = data.pagination.total
      this.formData.pageSize = data.pagination.pageSize;
      this.formData.currentPage = data.pagination.current;
    },
		handleSizeChange(num) {
			console.log(num)
      this.formData.pageSize = num;
			this.getTableList()
		},
		handleCurrentChange(num) {
			console.log('currentPage',num)
      this.formData.currentPage = num;
			this.getTableList()
		},
  }
};
</script>

<style lang="less" scoped>
.productList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .add {
    margin-top: 20px;
  }
  .el-table {
    margin-top: 50px;
  }
  .search_box {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .btn {
      margin: 0 20px;
    }
    span {
      display: inline-block;
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #F1F1F1;
  }
  /deep/.el-dialog__footer {
    border-top: 1px solid #F1F1F1;
  }
  .btn_box {
    margin-top: 24px;
  }
  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  /deep/.popconfirm {
    margin-right: 10px;
  }
  
}
</style>